import * as React from "react"
import styled from 'styled-components';

import Logo from '../assets/images/mockingbird-logo.svg';
import { GlobalTheme, theme } from "../theme/global-theme";
import '../theme/global.css';

const StyledIndex = styled.main`
  background-color: ${theme.colors.accent};
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  color: white;

  h1 {
    display: none;
  }

  h2 {
    font-weight: bold;
    font-size: 1.5rem;
  }

  svg.logo {
    max-width: 500px;
    width: 100%;
    margin-bottom: 2rem;

    path {
      fill: white;
    }
  }
`

// markup
const IndexPage = () => {
  return (
    <StyledIndex>
      <GlobalTheme />
      <title>Mockingbird</title>
      <Logo className="logo" />
      <h1>Mockingbird</h1>

      <h2>Coming soon...</h2>
    </StyledIndex>
  )
}

export default IndexPage
