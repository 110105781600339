import { createGlobalStyle } from "styled-components";

export const GlobalTheme = createGlobalStyle`
  
`;

export const theme = {
  colors: {
    accent: '#7561f6',
    main: '#434343',
  }
}